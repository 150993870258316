import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper"

import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/pagination"

import Layout from "../components/layout"
import SectionTitle from "../components/SectionTitle"
import Seo from "../components/seo"
import SignificantSubtitle from "../components/SignificantSubtitle"
import CustomList from "../components/CustomList"

import data from "../helpers/content.json"
import LabelImportant from "@mui/icons-material/LabelImportant"
import { pageSetting, theme as customTheme } from "../helpers/theme"
import CardPrice from "../components/CardPrice"
import ContactForm from "../components/ContactForm"
import { capitalFirstLetter } from "../helpers/common"

const Prices = () => {

  const { title, text, content } = data["contact-us"]
  const { dependency, prices } = content
  const dependArr = dependency.split(",")
  const pricesArr = prices.split(",")
  const pricesArr2 = pricesArr.map(el => el.split(" - "))

  return (
    <Layout>
      <Seo title={capitalFirstLetter(title)} />
      <SectionTitle title={title} />
      <Swiper
        modules={[Autoplay]}
        spaceBetween={70}
        slidesPerView={1}
        breakpoints={{
          560: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          780: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        grabCursor
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3000,
        }}
      >
        {pricesArr2.map(el => (
          <SwiperSlide key={el}>
            <div css={{ display: "flex", justifyContent: "center" }}>
              <CardPrice title={el[0]} text={el[1]} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <SignificantSubtitle text={text} style={styles.subtitle} />
      <CustomList
        items={dependArr}
        icon={<LabelImportant css={{ color: pageSetting.attention }} />}
      />
      <SectionTitle title='Wypełnij formularz' />
      <SignificantSubtitle text="a my skontaktujemy się z Tobą" style={styles.subtitle} />
      
      <ContactForm />
    </Layout>
  )
}

const styles = {
  subtitle: {
    fontSize: "1.50",
    textAlign: "center",
    margin: `${customTheme.gutterMedium * 3}px auto`,
  },
  priceCards: {
    ".swiper-slide": {
      display: "flex",
      justifyContent: "center",
    },
  },
}

export default Prices
