import React, { useState } from "react"

import { Button, MenuItem, Select, TextField, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { pageSetting, palette, theme } from "../helpers/theme"
import CustomSnackBar from "../components/SnackBar"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const generateForm = () => ({
  Imie_Nazwisko: "",
  email: "",
  phone: "",
  Ilosc_Wlascicieli: "",
  Ilosc_Dokumentow_Ksiegowych: "",
  Ilosc_Zatrudnionych_Osob: "",
  Informacje_Dodatkowe: "",
  Forma_Prawna_Firmy: "",
  Rodzaj_prowadzonej_Dzialalnosci: "",
  Rodzaj_Dokumentacji_rachunkowej: "",
})

const setDefaultSnack = () => ({
  open: false,
  message: null,
  variant: "info",
})

const validate = form => {
  const tR = {}
  if (!form.Imie_Nazwisko) tR.Imie_Nazwisko = true
  if (!form.email) tR.email = true
  if (!form.phone) tR.phone = true

  return tR
}

const ContactForm = () => {
  const [form, setForm] = useState(generateForm())
  const [snackBar, setSnackBar] = useState(setDefaultSnack())
  const [validation, setValidation] = useState()
  const muiTheme = useTheme()
  const matches = useMediaQuery(muiTheme.breakpoints.up("sm"))

  const closeSnackBar = (e, reason) => {
    if (reason === "clickaway") return

    setSnackBar(setDefaultSnack())
  }

  const handleFormChange = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isValid = validate(form)
    if (Object.keys(isValid).length === 0) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "priceForm", ...form }),
      })
        .then(() => {
          setSnackBar({
            ...snackBar,
            open: true,
            message: "Twój formularz został wysłany",
          })
          setForm(generateForm())
          setValidation(null)
        })
        .catch(error =>
          setSnackBar({
            ...snackBar,
            open: true,
            message: "Coś poszło nie tak. Skontaktuj się z nami telefonicznie.",
            variant: "warning",
          })
        )
    } else {
      setValidation(isValid)
      setSnackBar({
        ...snackBar,
        open: true,
        message: "Brakuje kilku Twoich danych",
        variant: "info",
      })
    }
  }

  const configSet = [
    {
      id: "entry.1294165495",
      name: "Imię i Nazwisko",
      val: form.Imie_Nazwisko,
      func: handleFormChange,
      props: {
        fullWidth: true,
        type: "text",
        name: "Imie_Nazwisko",
        error: validation?.Imie_Nazwisko,
      },
    },
    {
      id: "entry.87705371",
      name: "Email",
      val: form.email,
      func: handleFormChange,
      props: {
        fullWidth: true,
        type: "email",
        name: "email",
        error: validation?.email,
      },
    },
    {
      id: "entry.308401553",
      name: "Telefon",
      val: form.phone,
      func: handleFormChange,
      props: {
        fullWidth: true,
        type: "tel",
        name: "phone",
        error: validation?.phone,
      },
    },
    {
      id: "entry.1717793686",
      name: "Ilość właścicieli (udziałowców) firmy",
      val: form.Ilosc_Wlascicieli,
      func: handleFormChange,
      props: { fullWidth: true, type: "number", name: "Ilosc_Wlascicieli" },
    },
    {
      id: "entry.1722135592",
      name: "Ilość dokumentów księgowych",
      val: form.Ilosc_Dokumentow_Ksiegowych,
      func: handleFormChange,
      props: {
        fullWidth: true,
        type: "number",
        name: "Ilosc_Dokumentow_Ksiegowych",
      },
    },
    {
      id: "entry.1999341151",
      val: form.Ilosc_Zatrudnionych_Osob,
      func: handleFormChange,
      name: "Ilość zatrudnionych osób na umowę o pracę oraz na umowę zlecenie/umowę o dzieło",
      props: {
        fullWidth: true,
        type: "number",
        name: "Ilosc_Zatrudnionych_Osob",
      },
    },
    {
      id: "entry.1535729874",
      name: "Forma prawna firmy",
      val: form.Forma_Prawna_Firmy,
      dropdown: true,
      props: { fullWidth: true, name: "Forma_Prawna_Firmy" },
      func: handleFormChange,
      opt: [
        "jednoosobowa działalność gospodarcza",
        "spółka cywilna",
        "spółka jawna",
        "spółka partnerska",
        "spółka komandytowa",
        "spółka komandytowo-akcyjna",
        "spółka z ograniczoną odpowiedzialnością",
        "spółka akcyjna",
      ],
    },
    {
      id: "entry.2014137289",
      name: "Rodzaj prowadzonej dokumentacji rachunkowej",
      val: form.Rodzaj_Dokumentacji_rachunkowej,
      dropdown: true,
      props: { fullWidth: true, name: "Rodzaj_Dokumentacji_rachunkowej" },
      func: handleFormChange,
      opt: ["ryczałt", "księga przychodów i rozchodów", "pełne księgi"],
    },
    {
      id: "entry.1225137019",
      name: "Rodzaj prowadzonej działalności (np.: handel, produkcja, usługi)",
      dropdown: true,
      val: form.Rodzaj_prowadzonej_Dzialalnosci,
      func: handleFormChange,
      props: { fullWidth: true, name: "Rodzaj_prowadzonej_Dzialalnosci" },
      opt: ["Usługi", "Handel", "Produkcja"],
    },
    {
      id: "entry.1194648110",
      name: "Informacje dodatkowe",
      val: form.Informacje_Dodatkowe,
      func: handleFormChange,
      props: {
        multiline: true,
        rows: 6,
        fullWidth: true,
        name: "Informacje_Dodatkowe",
      },
    },
  ]

  return (
    <div>
      <div css={styles.root(matches)}>
        <form
          onSubmit={handleSubmit}
          name="priceForm"
          method="POST"
          data-netlify="true"
          action="/"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="priceForm" />
          {configSet.map(set =>
            set.dropdown ? (
              <div css={styles.dropdowns} key={set.id}>
                <Typography variant="body1" css={styles.text}>
                  {set.name}
                </Typography>
                <Select
                  name={set.id}
                  value={set.val}
                  onChange={set.func}
                  {...set.props}
                >
                  {set.opt.map(op => (
                    <MenuItem key={op} value={op}>
                      {op}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ) : (
              <div css={styles.questionBox} key={set.id}>
                <Typography variant="body1" css={styles.text}>
                  {set.name}
                </Typography>
                <TextField value={set.val} onChange={set.func} {...set.props} />
              </div>
            )
          )}
          <Button type="submit" variant="contained" css={styles.btn}>
            Wyślij
          </Button>
        </form>
      </div>
      <CustomSnackBar
        text={snackBar.message}
        open={snackBar.open}
        onClose={closeSnackBar}
        variant={snackBar.variant}
      />
    </div>
  )
}

const styles = {
  root: size => ({
    maxWidth: size ? "70%" : "100%",
    border: ".5px solid black",
    boxShadow: `0 0 10px ${palette.darkGrey}`,
    borderRadius: theme.radiusBig,
    margin: `${theme.gutterBig * 6}px auto`,
    padding: theme.gutterBig,
  }),
  questionBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    marginBottom: theme.gutter,
    marginTop: theme.gutter,
  },
  input: {},
  textArea: {},
  dropdowns: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "left",
    marginBottom: theme.gutter,
    marginTop: theme.gutter,
  },
  text: {
    ...pageSetting.text,
  },
  btn: {
    marginTop: theme.gutterBig,
    ...pageSetting.btn,
  },
}

export default ContactForm
