import { Card, CardContent } from "@mui/material"
import CardTitle from "./CardTitle"
import React from "react"
import { pageSetting, palette, theme } from "../helpers/theme"

const CardPrice = ({ title, text, style }) => {
  return (
    <Card css={styles.root(style)}>
      <CardTitle style={styles.title}>{title}</CardTitle>
      <CardContent css={styles.content}>{text}</CardContent>
    </Card>
  )
}

const styles = {
  root: style => ({
    maxWidth: 280,
    height: 180,
    padding: theme.gutterMedium,
    margin: theme.gutterMedium,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "all .5s ease-out",
    boxShadow: `0 0 5px ${palette.darkGrey}`,
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
    ...style,
  }),
  title: {
    paddingTop: theme.gutterMedium,
    fontWeight: 700,
    fontSize: "1.2rem",
    ...pageSetting.text,
  },
  content: {
    fontFamily: pageSetting.fontFamily,
    fontSize: "1.7rem",
    color: pageSetting.attention,
  },
}

export default CardPrice
