import { Typography } from "@mui/material"
import React from "react"

const CardTitle = ({ title, children, style }) => {
  return (
    <Typography css={style}>{(title || children).toUpperCase()}</Typography>
  )
}

export default CardTitle
