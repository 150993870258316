import { Typography } from "@mui/material"
import React from "react"
import { pageSetting } from "../helpers/theme"

const SignificantSubtitle = ({ children, text, style }) => {
  return (
    <Typography variant="h5" css={{ ...style, ...pageSetting.text }}>
      {text || children}
    </Typography>
  )
}

export default SignificantSubtitle
